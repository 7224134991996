import React from "react";
import loadable from "@loadable/component";
import firstResult from "@images/discovery-and-planning/result1.svg";
import secondResult from "@images/discovery-and-planning/result2.svg";
import thirdResult from "@images/discovery-and-planning/result3.svg";
import fourthResult from "@images/discovery-and-planning/result4.svg";
import { ResultsSectionContainer } from "./ResultsSection.style";

const HoverInfoSection = loadable(() =>
    import("@common/CommonSections/HoverInfoSection/HoverInfoSection")
);
const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const valueImages = [
    {
        svgPath: firstResult,
        alt: "Project Vision",
    },
    {
        svgPath: secondResult,
        alt: "System Requirements",
    },
    {
        svgPath: thirdResult,
        alt: "UX/UI Concept Design",
    },
    {
        svgPath: fourthResult,
        alt: "Plan and Estimates for an MVP",
    },
];

const descriptions = [
    `
        We will validate your idea by analyzing your competitors and your end-users 
        as well as evaluating potential risks and opportunities.
    `,
    `
        We will set both technical and non-technical requirements as well as give 
        you the scope of work with specific deadlines so it will be easier to hire
        software developers
    `,
    `
        We will present you with a clickable prototype and wireframes as a part of 
        our discovery phase services.
    `,
    `
        We will compose a plan that would include everything needed for MVP development: 
        time and budget estimates, product concept and workflows as well as all 
        the necessary documentation.
    `,
];

const ResultsSection = () => (
    <ResultsSectionContainer>
        <CircleBLur width="1114px" height="457px" />
        <HoverInfoSection
            heading="Results"
            headingDescription="After the end of the Product discovery stage you will get:"
            valueImages={valueImages}
            descriptions={descriptions}
            leftOffset="460px"
            topOffset="-60px"
        />
    </ResultsSectionContainer>
);

export default ResultsSection;
